.nav {
    a{
        color: white;
        font-family: monospace;
    }
    a:hover {
        color: grey;
        transition: .2s ease-in-out;
    }
    a.active {
        color: grey;
    }
    span {
        display: none;
    }
}

.logo {
    color: white;
    width: 80px;
}

.logo:hover {
    color: white;
    stroke-width: 3;
    stroke: white;
    transition: .3s ease-in-out;
}


.toggle{
    border: transparent !important;
}

.hamburger {
    font-size: 1.5rem !important;
}
