@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css";
$black: #333333;
.fa {
    font-size: 2rem !important;
    text-decoration: none;
    background-color: $black;
    color: white;
}

.nav-link.active {
    color: white;
    font-weight: bold;
}

