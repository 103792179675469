$border-right: .5em white solid;

.typewriter {
    overflow: hidden;
    width: 0;
    white-space: nowrap;
    font-family: monospace;
    h1 {
        border-right: $border-right; /* The typewriter cursor */
        animation: typing .5s steps(10) forwards, blink .5s step-end forwards;
    }
    
    h2,h6 {
        border-right: $border-right; /* The typewriter cursor */
        opacity: 0;
        animation: typing 1s steps(30) forwards, blink 1s step-end forwards;
        animation-delay: .5s;
    }

    p {
        border-right: $border-right; /* The typewriter cursor */
        opacity: 0;
        animation: typing .3s steps(10) forwards, blink 2s step-end infinite;
        animation-delay: 1.5s;
    }
}


@keyframes typing {
    from {width: 0;}
    1% {opacity: 100;}
    to {width: 100%; opacity: 100;}
}

/* The typewriter cursor effect */
@keyframes blink {
    from, to, 50% { border-color: transparent }
    25%, 75% { border-color: white; }
}

div {
    a{
        color: white;
        text-decoration: none;
    }
    a:hover{
        color: grey;
        transition: 0.2s ease-in-out;
    }
}

.name{
    color: #b4ba7d;
}

.text_small {
    font-size:large;
}
